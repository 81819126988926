<template>
  <div>
    <h2 class="mt-5">Similar topics</h2>
    <div>
      <Tag
        v-for="tag in tags"
        :key="tag"
        class="mr-3 my-2"
        @click="onTagClick(tag)"
      >
        {{ tag }}
      </Tag>
    </div>
  </div>
</template>

<script>
import Tag from '../Elements/Tag';

export default {
  name: 'SimilarTopics',

  components: {
    Tag
  },

  props: {
    tags: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    onTagClick(tag) {
      this.$router.push({ name: 'search', query: { keyword: tag } });
    }
  }
};
</script>
