<template>
  <span class="tag" @click="onTagClick">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'Tag',

  props: {
    variant: {
      type: String,
      default: 'light',
      validator: (value) => ['light'].includes(value)
    }
  },

  methods: {
    onTagClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/components';

.tag {
  display: inline-block;
  cursor: pointer;
  color: $white-color;
  border: 1px solid $light-grey-color;
  background-color: transparent;
  font-size: $font-size-small;
  padding: 6px 16px;
  border-radius: $tag-border-radius;
  line-height: 18px;
}
</style>
