<template>
  <div class="creator">
    <div class="page creator__preview-container container mb-1">
      <div class="row">
        <div class="col-md-5 image-container">
          <MainImagePreview
            :img-link="creatorPreviewImage"
            :video-url="$get(creator, 'profile.videoUrl')"
          />
        </div>

        <div
          class="col-md-7 text-container d-flex flex-column justify-content-center"
        >
          <h2 class="custom-title">
            {{ $get(creator, 'profile.brandName') }}
          </h2>

          <div class="creator__handle mt-3 flex-wrap w-100">
            <span>@{{ $get(creator, 'profile.handle') }}</span>

            <CreatorLinks
              v-if="$get(creator, 'platforms', []).length"
              class="ml-auto"
              source-type="creator"
              :data="creator"
            />
          </div>

          <div
            v-if="$get(creator, 'profile.biography')"
            class="creator__description mt-4"
          >
            <ViewMore
              :text="$get(creator, 'profile.biography')"
              :lines="4"
              :line-height="22"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="page mt-5 container">
      <b-row v-if="product">
        <b-col md="12" class="my-4">
          <h2>Latest from {{ $get(creator, 'profile.brandName') }}</h2>
        </b-col>
        <b-col md="5">
          <ProductImages
            :images="previewImages"
            :is-pre-order="isPreOrder"
            :video-file="videoFile"
          />
        </b-col>
        <b-col md="7">
          <PurchaseProduct
            :product="product"
            :questions="questions"
            @onQuantityUpdate="setQuantity"
          />

          <div v-if="isBannerEnabled" class="banner">
            <AdBanner type="half" />
          </div>
        </b-col>
      </b-row>

      <ProductBundlePanel
        v-if="bundleProduct"
        :products="[product, bundleProduct]"
        @click="handleProductBundleClick"
      >
        <template #title>
          <h4>Frequently bought together</h4>
        </template>
      </ProductBundlePanel>

      <div
        v-if="
          $get(product, 'availability') !== productAvailability.PreOrder &&
          recipes &&
          recipes.length
        "
      >
        <h2 class="mt-5">Featured Content</h2>
        <RecipeGrid
          :type="viewType.CUSTOM"
          :count-items="6"
          :recipes="recipes"
          @click="handleRecipeClick"
        />
      </div>

      <Carousel
        v-if="creatorProducts && creatorProducts.length"
        id="creatorProducts"
        class="my-4"
        :items="creatorProducts"
        :mode="CarouselModes.PRODUCT"
        show-default-item-actions
        @click="handleProductBundleClick"
      >
        <template #title>
          <h2>More from {{ $get(creator, 'profile.brandName') }}</h2>
        </template>
      </Carousel>

      <Carousel
        v-if="contributedProducts && contributedProducts.length"
        id="contributedProducts"
        class="my-5"
        :items="contributedProducts"
        :mode="CarouselModes.PRODUCT"
        show-default-item-actions
        @click="handleProductBundleClick"
      >
        <template #title>
          <h2>Also featuring {{ $get(creator, 'profile.brandName') }}</h2>
        </template>
      </Carousel>

      <SimilarTopics v-if="creatorTags.length" :tags="creatorTags" />

      <CreatorCarousel
        id="recommendedCreators"
        class="mt-5"
        :items="recommendedCreators"
        description-visible
        @click="handleCreatorClick"
      >
        <template #title>
          <h2>Discover creators</h2>
        </template>

        <template #subtitle>
          <span>
            Find other chefs doing similar things to me plus other cuisines and
            recipes I love...
          </span>
        </template>
      </CreatorCarousel>

      <TrendingBlock v-if="trendingFeed" class="mt-5" :items="trendingFeed" />
    </div>
  </div>
</template>

<script>
import { inRange, get, orderBy } from 'lodash';
import { mapState, mapGetters } from 'vuex';

import ProductImages from '@/components/ProductImages';
import MainImagePreview from '@/components/MainImagePreview';
import ViewMore from '@/components/ViewMore';

import { fetchOneCreator, fetchCreatorsRecommended } from '@/services/creators';
import {
  fetchProductsByCreator,
  fetchProductsRecommended
} from '@/services/products';
import { fetchTrendingFeed } from '@/services/selections';
import { fetchProductConfig } from '@/services/config';
import {
  CarouselModes,
  FallbackImageURL,
  ImageTypes,
  ProductAvailability
} from '@/misc/constants';
import { viewType } from '~/components/RecipeGrid/componentTypes';
import SimilarTopics from '~/components/SimilarTopics';
import CreatorLinks from '~/components/CreatorLinks';
import PurchaseProduct from '~/components/PurchaseProduct';
import getPreviewImages from '~/misc/getPreviewImages';
import getFormatPrice from '@/misc/getFormatPrice';
import formatMoney from '@/misc/formatMoney';
import getDefaultProductFormat from '@/misc/getDefaultProductFormat';
import { productAvailability } from '@/checkoutForm/lib/constants';
import filterProductsByRegion from '@/misc/filterProductByRegion';
import AdBanner from '@/components/AdBanner/index.vue';

export default {
  name: 'OneCreator',

  components: {
    AdBanner,
    SimilarTopics,
    CreatorLinks,
    PurchaseProduct,
    ProductImages,
    MainImagePreview,
    ViewMore,
    TrendingBlock: () =>
      import(/* webpackPrefetch: true */ '@/components/TrendingBlock/Index'),
    ProductBundlePanel: () =>
      import(/* webpackPrefetch: true */ '@/components/ProductBundlePanel'),
    Carousel: () =>
      import(/* webpackPrefetch: true */ '@/components/Carousel/Index'),
    CreatorCarousel: () =>
      import(/* webpackPrefetch: true */ '@/components/CreatorCarousel'),
    RecipeGrid: () =>
      import(/* webpackPrefetch: true */ '@/components/RecipeGrid/Index')
  },

  asyncData({ $axios, route, error }) {
    return fetchOneCreator($axios, route.params?.id)
      .then((creator) => {
        return { creator };
      })
      .catch((err) => {
        const errorCode = get(err, 'response.data.code');
        error({ statusCode: inRange(errorCode, 400, 405) ? 404 : 500 });
        return { creator: {} };
      });
  },

  data() {
    return {
      productAvailability,
      CarouselModes,
      viewType,
      creatorProducts: [],
      contributedProducts: [],
      recipes: [],
      product: null,
      questions: [],
      trendingFeed: null,
      recommendedCreators: [],
      bundleProduct: null,
      quantity: 1
    };
  },

  head() {
    const title = `@${this.creator?.profile.handle} - ${this.creator?.profile.tagline} | Found it!`;
    return {
      title,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.creatorPreviewImage
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: this.creatorPreviewImage
        },
        {
          hid: 'description',
          name: 'description',
          content: `${this.creator.profile.shortDescription} | Found it!\nDiscover and get creative with their recipes, books and more. Also discover other top social creators`
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currency: 'config/currency',
      region: 'config/region',
      isBannerEnabled: 'config/isBannerEnabled'
    }),
    ...mapState('config', ['config']),
    ...mapState('main', ['menuVisible', 'mobileKeyboardVisible']),
    previewImages() {
      return getPreviewImages(this.product);
    },
    isPreOrder() {
      return this.product?.availability === ProductAvailability.PreOrder;
    },
    creatorPreviewImage() {
      return (
        this.creator?.profile?.photos?.find(
          (image) => image.role === ImageTypes.Main
        )?.imageUrl || FallbackImageURL
      );
    },
    creatorTags() {
      return this.$get(this.creator, 'profile.tags', []);
    },
    videoFile() {
      return this.product?.videoFile;
    }
  },

  mounted() {
    fetchProductsByCreator(this.$axios, this.creator?.id, {
      query: {
        $populate: [{ path: 'videoFile' }]
      }
    })
      .then((response) => {
        const creatorProducts = this.$get(response, 'creatorProducts');
        this.creatorProducts = filterProductsByRegion(
          creatorProducts,
          this.region
        );
        this.contributedProducts = filterProductsByRegion(
          this.$get(response, 'contributedProducts'),
          this.region
        );

        if (this.creatorProducts && this.creatorProducts.length) {
          const filteredProducts = filterProductsByRegion(
            [this.getCreatorProduct(this.creatorProducts)],
            this.region
          );
          this.product = filteredProducts.length ? filteredProducts[0] : null;
          this.creatorProducts = this.creatorProducts.filter(
            (product) => product._id !== this.product._id
          );

          // Removing recipes call at all TODO: bring back on request
          //
          // if (
          //   this.$get(this.product, 'availability') !==
          //   productAvailability.PreOrder
          // ) {
          //   fetchRecipesForOneProduct(this.$axios, this.product.id).then(
          //     (products) => {
          //       this.recipes = products?.data;
          //     }
          //   );
          // }

          const { storeRegion, locale } = this.config || {};

          fetchProductConfig(this.$axios, this.product?.id).then((response) => {
            this.questions = this.$get(response, 'questions', []);
          });

          fetchProductsRecommended(this.$axios, this.product.id, {
            query: { language: locale, country: storeRegion }
          })
            .then((products) => {
              this.bundleProduct = filterProductsByRegion(
                products?.data,
                this.region
              )?.[0];
            })
            .catch(() => {
              this.bundleProduct = null;
            });
        }
      })
      .catch((err) => {
        this.$showToastMessage(
          this.$get(err, 'response.data.message'),
          'Error',
          'danger'
        );
      });

    fetchCreatorsRecommended(this.$axios, this.creator?.id).then((response) => {
      this.recommendedCreators = response?.data;
    });

    fetchTrendingFeed(this.$axios).then((trendingFeed) => {
      this.trendingFeed = trendingFeed;
    });
  },

  methods: {
    handleCreatorClick(params) {
      this.$router.push({
        path: `/creators/${
          this.$get(params, 'profile.handle') || this.$get(params, 'id')
        }`
      });
    },
    handleProductBundleClick(product) {
      this.$router.push({
        path: `/products/${product.id || product.item.id}`
      });
    },
    handleRecipeClick(recipe) {
      this.$router.push({
        path: `/recipe/${this.$get(recipe, '_id')}`
      });
    },
    getProductFormatPrice(product) {
      const defaultFormat = getDefaultProductFormat(product);
      return formatMoney(
        getFormatPrice(defaultFormat, this.region)?.amount,
        this.currency
      );
    },
    setQuantity(quantity) {
      this.quantity = quantity;
    },
    getCreatorProduct(products) {
      const featuredProduct = products.find((product) => product.featured);
      if (featuredProduct) {
        return featuredProduct;
      }
      if (products.length === 1) {
        return products[0];
      }

      /*
      // sort all items by price
      const sortedProducts = orderBy(
        products,
        (product) =>
          getFormatPrice(getDefaultProductFormat(product), this.currency)
            ?.amount
      );
      // two highest price product
      const lastProduct = sortedProducts[sortedProducts.length - 1];
      const preLastProduct = sortedProducts[sortedProducts.length - 2];

      // if they have the same price return last updated product
      if (
        getFormatPrice(getDefaultProductFormat(lastProduct), this.currency)
          ?.amount ===
        getFormatPrice(getDefaultProductFormat(preLastProduct), this.currency)
          ?.amount
      ) {
        return orderBy(
          [lastProduct, preLastProduct],
          (product) => product.availableAt
        )[1];
      }
      */

      // sort all items by availableAt
      const sortedProducts = orderBy(
        products,
        (product) => product.availableAt
      );

      // the latest available products
      return sortedProducts[sortedProducts.length - 1];
    }
  }
};
</script>

<style scoped lang="scss">
.creator {
  &__preview-container {
    @media (max-width: $screen-width-lg) {
      .text-container,
      .image-container {
        max-width: 100%;
        flex: unset;
      }

      .text-container {
        margin-top: 40px;
      }
    }
  }

  &__handle {
    font-family: 'Poppins-SemiBold', sans-serif;
    display: flex;
    justify-content: space-between;
    width: auto;
    overflow-wrap: anywhere;

    @media (min-width: $screen-width-lg) {
      width: 400px;
    }
  }

  &__description {
    line-height: 22px;
  }
}
.tags {
  display: flex;
  flex-wrap: wrap;

  .tag {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
.banner {
  width: 100%;
  margin-top: 70px;

  @media (max-width: $screen-width-lg) {
    height: unset;
    max-height: unset;
  }
}
</style>
