const makeItBannerData = {
  desktopImage: require('@/assets/images/banners/make-it_desktop.jpg'),
  mobileImage: require('@/assets/images/banners/make-it_mobile.jpg'),
  targetPath: '/cookbooks/65e70020116b2e60f5b3b3c9'
};

const comeFixPlateBannerData = {
  desktopImage: require('@/assets/images/banners/come-fix-you-a-plate_desktop.jpg'),
  mobileImage: require('@/assets/images/banners/come-fix-you-a-plate_mobile.jpg'),
  targetPath: '/cookbooks/6406138164ba8cb8f6975bfe'
};

const kimmyBannerData = {
  desktopImage: require('@/assets/images/banners/kimmy_desktop.jpg'),
  mobileImage: require('@/assets/images/banners/kimmy_mobile.jpg'),
  targetPath: '/cookbooks/622f71f0727edf031a65bfda'
};

const foodiePhysicianBannerData = {
  desktopImage: require('@/assets/images/banners/foodie-physician-cookbook_desktop.jpg'),
  mobileImage: require('@/assets/images/banners/foodie-physician-cookbook_mobile.jpg'),
  targetPath: '/cookbooks/632432a6ce5d302991d34eb2'
};

export const productBannerMap = {
  '65e70020116b2e60f5b3b3c9': makeItBannerData,
  '6406138164ba8cb8f6975bfe': comeFixPlateBannerData,
  '622f71f0727edf031a65bfda': kimmyBannerData,
  '632432a6ce5d302991d34eb2': foodiePhysicianBannerData
};

export const creatorBannerMap = {
  melissajorealrecipes: makeItBannerData,
  yourbarefootneighbor: comeFixPlateBannerData,
  kimmyskreations: kimmyBannerData,
  thefoodiephysician: foodiePhysicianBannerData
};
