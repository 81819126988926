<template>
  <NuxtLink :href="targetPath" class="banner" :to="localePath(targetPath)">
    <img
      v-if="type === 'half'"
      class="d-md-none d-sm-block banner__img"
      :src="mobileImage"
    />
    <img
      v-if="type === 'half'"
      class="d-none d-md-block d-lg-block d-xl-block d-xxl-block banner__img"
      :src="desktopImage"
    />
  </NuxtLink>
</template>
<script lang="ts">
export default {
  name: 'ComeFixPlateBanner',
  props: {
    type: {
      type: String,
      default: 'half'
    },
    desktopImage: {
      type: String,
      required: true
    },
    mobileImage: {
      type: String,
      required: true
    },
    targetPath: {
      type: String,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.banner > div {
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  font-weight: bold;
  color: #d9d8da;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  &:hover {
    text-decoration: none;
  }
}

.banner__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
