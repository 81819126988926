<template>
  <DynamicHalfBanner
    v-if="dynamicBannerData"
    :type="type"
    :desktop-image="dynamicBannerData.desktopImage"
    :mobile-image="dynamicBannerData.mobileImage"
    :target-path="dynamicBannerData.targetPath"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import { productBannerMap, creatorBannerMap } from './constants';
import DynamicHalfBanner from '@/components/AdBanner/DynamicHalfBanner.vue';

export default {
  name: 'AdBanner',
  components: { DynamicHalfBanner },
  props: {
    type: {
      type: String,
      default: 'cookbooks'
    }
  },

  data() {
    return {
      pageNamesForDynamicBanner: ['creators-id', 'products-id', 'slug-id']
    };
  },

  computed: {
    ...mapGetters({
      region: 'config/region'
    }),

    dynamicBannerData() {
      if (this.pageNamesForDynamicBanner.includes(this.$route.name)) {
        const id = this.$get(this.$route.params, 'id');

        const data = productBannerMap[id] || creatorBannerMap[id];

        return data || null;
      }

      return null;
    }
  }
};
</script>
